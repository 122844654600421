.game-item-detail {
  min-width: 200px;
  max-width: 400px;
  color: white;
  display: grid;
  font-size: 1.2rem;
  grid-template-columns: 55px 1fr;
  grid-template-areas:
    "icon name"
    "icon stats"
    "description description"
    "combinations combinations";
}

.item-detail-tooltip.my-container {
  padding: 5px;
}

.game-item-detail-icon {
  width: 45px;
  height: 45px;
  align-self: center;
  object-fit: contain;
  grid-area: icon;
}

.game-item-detail-name {
  grid-area: name;
  margin: 0;
  white-space: nowrap;
  font-weight: 500;
}

.game-item-recipe {
  float: right;
}

.game-item-recipe img {
  width: 22.5px;
  height: 22.5px;
  object-fit: contain;
}

.game-item-detail-stats {
  grid-area: stats;
  display: flex;
  gap: 1em;
  font-size: 80%;
  margin: 0 0 0.5em 0;
  align-items: center;
  line-height: 1;
  min-height: 15px;
}

.game-item-detail-stats > div {
  display: flex;
  align-items: center;
}

.game-item-detail-stats img {
  width: 20px;
  height: 20px;
}

.game-item-detail-description {
  grid-area: description;
  background-color: #4f5160;
  box-shadow: 0 -1px 2px rgb(0 0 0 / 50%);
  padding: 0.25em 0.5em;
  margin: 0 -5px 4px -5px;
  font-size: 0.8em;
  z-index: 2;
  white-space: pre-line;
}

.game-item-detail-description:last-child,
.react-tooltip .game-item-detail-description:last-child {
  margin-bottom: -5px;
  border-radius: 0 0 8px 8px;
}

.game-item-detail-combinations {
  display: flex;
  justify-content: space-between;
  grid-area: combinations;
  gap: 0.5em;
  min-width: 320px;
}

.game-item-detail-combination {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.game-item-detail-combination > p {
  margin: 0;
  font-size: 60%;
  text-shadow: 0 0 2px black;
  text-align: center;
}

.game-item-detail-combination > img {
  width: 20px;
  height: 20px;
}
