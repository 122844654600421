.pokemon-emotions-modal.my-container {
  padding: 0;
}

.pokemon-emotions-modal header {
  padding: 0;
  color: white;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pokemon-emotions-modal header > img {
  width: 80px;
  height: 80px;
  margin-right: 0.5em;
}

.pokemon-emotions-modal header img.unlocked {
  filter: grayscale(0);
}

.pokemon-emotions-modal header > h2 {
  margin: 0;
}

.pokemon-emotions-modal header .dust {
  display: flex;
  align-items: center;
  color: white;
  font-size: 120%;
}

.pokemon-emotions-modal header img {
  width: 40px;
  height: 40px;
  margin: 0 0.5em;
}

.pokemon-emotions-modal img.dust {
  border-radius: 50%;
}

.pokemon-emotions-modal .modal-body {
  display: flex;
  justify-content: space-around;
}

.pokemon-emotions-modal section p {
  text-align: center;
  margin: 0;
}

.pokemon-emotions-modal section > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1vw;
  margin: 0 1vw;
}
