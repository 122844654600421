.game-pokemon-detail {
  color: #fff;
  font-size: 16px;
  display: grid;
  position: relative;
  max-width: 320px;
  gap: 5px;
  grid-template-columns: 88px 1fr auto;
}

.react-tooltip.game-pokemon-detail-tooltip,
.my-container.game-pokemon-detail-tooltip {
  padding: 5px;
}

.game-pokemon-detail p {
  margin: 0;
}

.game-pokemon-detail-entry {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.game-pokemon-detail-entry-name {
  text-transform: capitalize;
  font-size: 1.2em;
  font-weight: 600;
}

.game-pokemon-detail-entry-rarity {
  text-transform: uppercase;
  font-size: 90%;
}

.game-pokemon-detail-portrait {
  width: 80px;
  height: 80px;
  border-style: solid;
  border-width: 4px;
  border-radius: 8px;
  box-sizing: content-box;
}

.game-pokemon-detail-portrait-hint {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 23px;
  left: 7px;
  border-radius: 50%;
  filter: grayscale(1) contrast(0.2) brightness(1.8);
}

.game-pokemon-detail-types {
  display: flex;
  flex-wrap: wrap;
}

.game-pokemon-detail-stats {
  grid-column: span 3;
  display: grid;
  grid-template: 32px 32px / repeat(5, 1fr);
}

.in-shop .game-pokemon-detail-stats,
.in-board .game-pokemon-detail-stats {
  grid-template: 32px 32px / repeat(4, 1fr);
}

.game-pokemon-detail-stats > div {
  white-space: nowrap;
  overflow: visible;
}

.game-pokemon-detail-stats img {
  width: 32px;
  height: 32px;
}

.game-pokemon-detail-stats img + span {
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  font-size: 90%;
}

.game-pokemon-detail-stat-range {
  grid-row: span 2;
  display: flex;
  align-items: center;
}

.game-pokemon-detail-stats .game-pokemon-detail-stat-range img + span {
  display: inline-block;
  margin: 0.5em;
  font-size: 120%;
}

.game-pokemon-detail-stats .negative {
  color: red;
}

.game-pokemon-detail-dish {
  grid-column: span 3;
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  background-color: #4f5160;
  margin: 0 -5px -7px -5px;
  padding: 0 2px 5px 2px;
}

.game-pokemon-detail-dish-name {
  grid-column: span 2;
  padding: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5em;
  font-size: 1em;
  font-style: italic;
}

.game-pokemon-detail-dish-icon {
  width: 45px;
  height: 45px;
}

.game-pokemon-detail-ult {
  grid-column: span 3;
}

.game-pokemon-detail-ult .ability-name {
  background-color: #4f5160;
  text-transform: capitalize;
  font-variant: small-caps;
  padding: 0.25em 5px;
  margin: 0 -5px 4px -5px;
  font-size: 1.2em;
  box-shadow: 0 -1px 2px rgb(0 0 0 / 50%);
}

.game-pokemon-detail-ult .custom-description {
  background-color: #4f5160;
  padding: 0.25em 0.5em;
  margin: 0 -5px -5px -5px;
}

.game-pokemon-detail-passive {
  grid-column: span 3;
  font-style: italic;
  background-color: #4f5160;
  padding: 0.25em 5px;
  margin: 0 -5px -5px -5px;
  font-size: 1em;
  box-shadow: 0 -1px 1px rgb(0 0 0 / 50%);
  white-space: pre-wrap;
}

.game-pokemon-detail-passive:last-child {
  border-radius: 0 0 8px 8px;
}
