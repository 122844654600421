.user-menu {
  flex-basis: 30%;
  height: 90vh;
  overflow-y: scroll;
  color: white;
}

.user-menu .player-rank {
  line-height: 40px;
  min-width: 2ch;
  text-align: center;
}

ul.titles {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

ul.titles > li {
  display: flex;
  gap: 0.5em;
  margin: 0.1em 0;
  padding: 0.5em;
  list-style: none;
  justify-content: space-between;
}

ul.titles > li div {
  display: flex;
  gap: 0.5em;
}

ul.titles > li span {
  color: #e76e55;
}

ul.titles > li p {
  color: #a0a0a0;
}

ul.titles > li.unlocked {
  background-color: var(--color-bg-primary);
}

ul.titles > li.unlocked span {
  color: #92cc41;
}

ul.titles > li.unlocked p {
  color: white;
}

ul.titles > li.selected {
  background-color: #109fff;
}

ul.titles > li.selected span {
  color: var(--color-fg-gold);
}

ul.titles > li.selected p {
  color: white;
}

.search-suggestions {
  display: flex;
  padding: 0;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
}

.search-suggestions .player.my-box {
  display: flex;
  flex-flow: column;
  padding: 5px;
}

.search-suggestions .player.my-box.banned {
  color: red;
}

.search-suggestions .player.my-box img {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.player-history .actions {
  font-size: 80%;
  display: flex;
  gap: 0.25em;
  flex-wrap: wrap;
}
