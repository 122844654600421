.sprite-viewer-root {
  width: 100%;
  height: 100%;
}

.sprite-viewer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  padding: 40px;
  padding-left: calc(var(--sidebar-width) + 10px);
}

.sprite-viewer-toolbar.my-box {
  width: fit-content;
  max-width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 8px;
  margin: 0 auto;
  background-color: var(--color-bg-primary);
}

.sprite-viewer-toolbar label {
  display: block;
}

.sprite-viewer-toolbar label:not(.checkbox-container)::after {
  content: ":";
}

.sprite-viewer-sprite {
  display: flex;
  justify-content: center;
  width: 100%;
}
