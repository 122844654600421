.tournaments-admin {
  width: 1200px;
}

.tournaments-admin ul {
  padding: 0;
}

.tournaments-admin li {
  list-style: none;
}

.tournament-form {
  display: flex;
  gap: 0.5em;
  align-items: end;
}

.tournament-form label {
  display: block;
}

.tournaments-admin .actions {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.tournament-admin-item table {
  width: 100%;
}
