.booster-card {
  position: absolute;
  top: 5%;
  transition: all 1s ease-out;
  transform: translateX(-50%);
  height: 40%;
  aspect-ratio: 2/3;
  animation: 1s ease-in-out distribute;
  animation-fill-mode: forwards;
  transform-style: preserve-3d;
}

.booster-card:nth-child(5n + 1) {
  left: 10%;
}
.booster-card:nth-child(5n + 2) {
  left: 30%;
}
.booster-card:nth-child(5n + 3) {
  left: 50%;
}
.booster-card:nth-child(5n + 4) {
  left: 70%;
}
.booster-card:nth-child(5n + 5) {
  left: 90%;
}

.booster-card:nth-child(n + 6) {
  top: 55%;
}

.booster-card .back,
.booster-card .front {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: all 100ms ease-out;
}

.booster-card .back {
  transform: rotateY(0);
  border-radius: 1.5vw;
}

.booster-card .front {
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  background-color: var(--color-bg-primary);
  border: 4px solid var(--rarity-color, #161616);
  border-radius: 1em;
  overflow: hidden;
}

.booster-card:hover .back {
  box-shadow: 0 0 4vmax var(--rarity-color, white);
}

.booster-card:hover .back:hover {
  animation: 300ms horizontal-shaking infinite;
}

.booster-card:not(.flipped) {
  cursor: var(--cursor-hover);
}

.booster-card.flipped {
  animation: 500ms ease-in-out flipCard;
  animation-fill-mode: forwards;
  z-index: 2;
}

.booster-card.flipped .front {
  box-shadow: 0 0 4vmax var(--rarity-color, white);
}

@keyframes flipCard {
  0% {
    transform: translateX(-50%) rotateY(0) scale(1);
  }

  50% {
    transform: translateX(-50%) rotateY(90deg) rotateZ(-10deg) scale(1.25);
  }

  100% {
    transform: translateX(-50%) rotateY(180deg) scale(1);
  }
}

@keyframes distribute {
  0% {
    position: absolute;
    top: 100%;
    left: 50%;
  }
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}

.booster-card .back img {
  width: 100%;
  height: 100%;
}

.booster-card .front img {
  width: 100%;
  height: 65%;
}

.booster-card .front-text {
  position: absolute;
  top: 65%;
  bottom: 0;
  left: 0;
  right: 0;
}

.booster-card .name {
  text-align: center;
  background-color: #4f5160;
  text-transform: capitalize;
  font-variant: small-caps;
  padding: 0.25em 0.5em;
  margin: 0 -5px 4px -5px;
  font-size: 1em;
  box-shadow: 0 0.1em 0.25em rgb(0 0 0 / 50%);
  line-height: 1;
}

.booster-card.shiny .name {
  color: gold;
}
