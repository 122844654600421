.quest ul{
  align-items: end;
  list-style: none;
  padding: 0;
  margin: 0.5em;
  display: flex;
  gap: 1em;
  height: 500px;
}

.quest-box {
  border-radius: 12px;
  background-color: var(--color-bg-primary);
  border: var(--border-thick);
  padding: 0.25em 0.5em;
  color: white;
  height: 100%;
  width: 1800px;
  bottom: 0;
  position: relative;
}

.full_button {
  width: 97%;
  position: absolute;
  bottom: 5px;
}

.small_button {
  width: 30%;
  position: absolute;
  bottom: 5px;
  left: 68%;
}

.button {
  width: 65%;
  position: absolute;
  bottom: 5px;
  left: 0%;
}

h2 {
  text-align: center;
  font-size: xx-large;
}

.objective-reward {
  position: absolute;
  bottom: 12%;
  font-weight: bold;
}

.quest p {
  white-space: pre-line;
}