#team-builder {
  width: 100%;
  display: grid;
  grid-template-areas: "synergies actions selected" "synergies board selected" "pokemons pokemons items";
  grid-template-columns: 250px 1fr 330px;
  grid-template-rows: min-content 1fr 420px;
  min-width: 1400px;
  gap: 5px;
  padding: 5px;
  height: 75vh;
  overflow-y: auto;
  position: relative;
}

.team-builder-modal .modal-body {
  padding: 0;
}

.modal-body > #team-builder {
  border-radius: 0 0 12px 12px;
  width: 2000px;
  max-width: 94vw;
}

#team-builder .synergies-container {
  grid-area: synergies;
  background-color: var(--color-bg-secondary);
  border: var(--border-thin);
}

#team-builder .actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 1em;
}

#team-editor {
  grid-area: board;
  display: grid;
  place-content: center;
  position: relative;
}

#team-editor td {
  width: 84px;
  height: 84px;
  cursor: var(--cursor-hover);
  padding: 0;
  background-color: var(--color-bg-secondary);
  border: var(--border-thick);
  position: relative;
  background-clip: padding-box;
}

#team-editor td.dragover {
  background-color: #109fff;
}

#team-editor td img {
  width: 80px;
  height: 80px;
}

#team-editor td .pokemon-items {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  gap: 1px;
}

#team-editor td .pokemon-items > img {
  height: 26px;
  width: 26px;
  object-fit: contain;
  border-radius: 50%;
  border: var(--border-thin);
  background-color: var(--color-bg-secondary);
}

#selected-entity {
  grid-area: selected;
  align-self: start;
  background-color: var(--color-bg-primary);
  padding: 5px;
  overflow-y: auto;
  max-height: 100%;
}

#selected-entity fieldset {
  color: white;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  border: none;
  padding: 0;
  margin: 0 0 0.5em 0;
}

#item-picker {
  grid-area: items;
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  overflow: auto;
  padding: 5px;
}

#item-picker .react-tabs__tab {
  font-size: 80%;
  padding: 4px 8px;
}

#item-picker img.item {
  width: 40px;
  height: 40px;
  cursor: var(--cursor-hover);
  margin: 2px;
}

#pokemon-picker {
  grid-area: pokemons;
  overflow-y: auto;
  padding: 5px;
}

#pokemon-picker .selected,
#item-picker .selected {
  outline: 4px solid #109fff;
}

#pokemon-picker .pokemon-portrait {
  width: 40px;
  height: 40px;
  cursor: var(--cursor-hover);
}

#pokemon-picker .pokemon-portrait img {
  display: block;
}

#pokemon-picker .react-tabs__tab {
  padding: 0;
  text-align: center;
  vertical-align: bottom;
  min-width: max(1ch, 40px);
  line-height: 40px;
  margin: 0 1px;
}

#pokemon-picker .react-tabs__tab img {
  display: block;
}

#pokemon-picker .synergy-overlaps {
  display: flex;
  gap: 0.5em;
  padding: 0;
  flex-wrap: wrap;
}

#pokemon-picker summary {
  margin: .5em 0;
  font-size: 1rem;
}

#pokemon-picker .filters label {
  float: right;
  margin-left: 1em;
}

#rarity-grid {
  display: grid;
  grid-template: auto / 8ch 1fr 8ch 1fr;
  margin: 0;
  gap: 0 0.5em;
}

#bot-info {
  position: absolute;
  top: 4px;
  left: 280px;
}

#bot-info .bot-avatar {
  width: 80px;
  height: 80px;
  border: var(--border-thick);
  border-radius: 8px;
}

#bot-info p {
  margin: 0;
}

@media (width <= 640px) {
  #team-builder {
    grid-template-areas: "synergies" "board" "selected" "pokemons" "items";
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    grid-auto-rows: min-content;
    min-width: auto;
    gap: 5px;
    height: 75vh;
    overflow-y: auto;
  }

  .modal-body > #team-builder {
    width: 95vw;
  }

  #team-editor td {
    width: 44px;
    height: 44px;
    border-width: 2px;
  }

  #team-editor td img {
    width: 40px;
    height: 40px;
  }

  #pokemon-picker {
    padding: 2px;
  }

  #rarity-grid {
    grid-template: auto / auto;
  }

  #bot-info {
    position: static;
  }
}
