.patchnotes.my-container {
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.patchnotes h1 {
  font-size: 150%;
  text-shadow: 2px 2px 2px #00000040;
}

.patchnotes .modal-body {
  flex: 1;
  overflow-y: scroll;
}

.patchnotes .modal-body h2 {
  font-size: 120%;
  border-bottom: 2px solid #80808080;
}

.patchnotes .modal-body ul {
  padding: 0;
  margin: auto;
  justify-content: center;
}

.patchnotes .modal-body blockquote {
  text-align: right;
  color: gray;
  margin: 0;
}

.patchnotes .modal-body hr {
  height: 2px;
  background-color: white;
  margin: 1em 0;
}

.patchnotes .modal-body > ul {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding: 0;
}

.patchnotes .modal-body > ul > li {
  list-style: none;
  padding: 0;
}

@media (max-width: 640px) {
  .patchnotes .modal-body {
    padding: 0;
  }
}
